
@import 'src/styles/variables';
@import 'mixins/spacing';

.c-card {
  // border-radius: $app-border-radius;
  border: 1px solid $app-border-color;
  background-color: $white;

  &--landing-page {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: $ihd-screen-width-lg) {
      background-color: $card-bg-color-mobile;
      display: flex;
      flex-direction: column;
      border-radius: 0;
      border: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__header {
    background-color: $white;
    @include p(5);
    border-top-right-radius: $app-border-radius;
    border-top-left-radius: $app-border-radius;

    @media (max-width: $ihd-screen-width-md - 1px) {
      display: flex;
      flex-direction: column;
      border-radius: 0;
    }
  }

  &__body {
    display: flex;

    @media (min-width: $ihd-screen-width-lg) {
      flex: 3;
      min-height: 640px;
    }

    &--minimal {
      align-items: center;
      justify-content: center;
      display: flex;
      
      @include py(5);

      @media (max-width: $ihd-screen-width-sm) { 
        background-color: #F7F8FB;
        padding-top: 5rem;
        padding-bottom: 5rem;
      }

      @media (max-width: $ihd-screen-width-md - 1px) {
        background-color: #F7F8FB;
        padding-top: 5rem;
      padding-bottom: 5rem;
      }
    }
  }

  &__footer {
    @include p(5);
    background-color: $card-footer-bg-color;
    flex: 1;

    @media (max-width: $ihd-screen-width-lg) {
      @include py(2);
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 0;
      justify-content: flex-start;
      flex: 1;

      &--help {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: flex-start;
    padding-top: 60px;
      }
    }

    &--minimal {
      @media (max-width: $ihd-screen-width-md) {
        background-color: #F7F8FB;
      }
    }
  }

  &__title {
    @include mt(5);
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
  }

  &--dark {
    border: 1px solid #181A20;

    .c-card__footer {
      color: $white;
      background-color: #181A20;

      &--minimal {
        background-color: #181A20;
      }
    }

    .c-card__body {
      background-color: $card-bg-color-secondary-dark;
      color: $white;

      @media (max-width: $ihd-screen-width-lg) {
        background-color: #181A20;
      }

      &--minimal {
        background-color: $card-bg-color-secondary-dark;

        @media (max-width: $ihd-screen-width-lg) {
          background-color: #181A20;
        }
      }
    }

    .c-card__title {
      color: $white;
    }
    .c-card__desc {
      color: $white;
    }
  }
}