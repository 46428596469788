/* ****************************************************************************************
 *
 * Utils
 *
 * ****************************************************************************************/

//
// Color system
//

// stylelint-disable
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default; //edeff2
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #0070ed !default; // 3374de !default; // #2cd7f5 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #F3CD84 !default;
$green:   #84CDA1 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$turquoise: #ace3e5 !default;
$mustard: #edcd8e !default;
$mint:    #a5d9ac !default;

$colors: () !default;
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
), $colors);

$original:       #2cd7f5 !default;
$primary:        $blue !default;
$secondary:      $gray-200 !default;
$success:        $green !default;
$info:           $cyan !default;
$warning:        $yellow !default;
$danger:         $red !default;
$light:          $gray-100 !default;
$dark:           $gray-800 !default;
$feature:        #a6d4d7 !default;
$go:            #77C43D !default;
$brand-pink: #FF77EC !default;
$brand-yellow: #FFC500 !default;
$brand-blue: #00E1EC !default;
$status-approved:$mint;
$status-available: #77C43D !default;
$status-active: $mint;
$status-closed:  $turquoise;
$status-pending: $mustard;
$status-upcoming: #CEE1F1 !default;
$status-upsell:  #F2F5FA !default;
$time-off-status-approved: #03D2AA !default;
$time-off-status-pending: #FFB717 !default;
$time-off-status-declined: #FA4730 !default;

$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);
// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$ihd-spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4),
) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Screen breakpoints
$ihd-screen-width-xs: 375px;
$ihd-screen-width-sm: 576px;
$ihd-screen-width-md: 768px;
$ihd-screen-width-lg: 992px;
$ihd-screen-width-xl: 1200px;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-xl:              1.8 !default;
$line-height-lg:              1.6 !default;
$line-height-md:              1.428571429 !default;
$line-height-sm:              1.3 !default;
$line-height-xs:              1.0 !default;
$line-height-xxs:             0.8 !default;

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;
$border-radius-xs:            .2rem !default;
$border-radius-xxs:           .2rem !default;

$component-active-color:      $white !default;

$caret-width:                 .3em !default;

$component-active-bg:         $primary !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;


$font-family-sans-serif:      "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$ihd-font-size-base:              0.75rem !default; // Assumes the browser default, typically `16px` 0.75 = 12px;
$font-size-xxxlg:             ($ihd-font-size-base * 2) !default;
$font-size-xxlg:              ($ihd-font-size-base * 1.75) !default;
$font-size-xlg:               ($ihd-font-size-base * 1.5) !default;
$font-size-lg:                ($ihd-font-size-base * 1.25) !default;
$font-size-md:                ($ihd-font-size-base * 1) !default;
$font-size-sm:                ($ihd-font-size-base * .75) !default;

$font-weight-lighter:         200 !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            500 !default;
$font-weight-bolder:          600 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.428571429 !default; //1.5

/* ****************************************************************************************
 *
 * Core
 *
 * ****************************************************************************************/

 $body-color:                  #363f44 !default;
 $body-bg-color:               #F5F5F5 !default;
 $body-bg-color-dark:          #181A20 !default;

 $app-border-radius:            12px;
 $app-border-color:             #EAEBEC !default;
 $app-base-shadow:              0 1px 0 $gray-200 !default;
 $app-background-color-dark:    #667680;


/* ****************************************************************************************
 *
 * Typography
 *
 * ****************************************************************************************/

 $text-muted:                  #AAB5BC !default; //$gray-600 !default;
 $text-muted-darker:           #888b92 !default;


/* ****************************************************************************************
 *
 * Map Component
 *
 * ****************************************************************************************/

 $map-bg-color:                 $white !default;
 $map-tooltip-border-top-color: $body-color !default;

 /* ****************************************************************************************
 *
 * Card
 *
 * ****************************************************************************************/
$card-footer-bg-color:            #FAFBFF;
$card-bg-color-mobile:            #FAFBFF;
$card-bg-color-primary-dark:      #22252D;
$card-bg-color-secondary-dark:    #212529;
$card-border-color-dark:          #667680;
$card-link-color:                 #65697A;