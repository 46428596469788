@mixin p($index) {
  padding: map_get($ihd-spacers, ($index));
}

@mixin px($index) {
  padding-left: map_get($ihd-spacers, ($index));
  padding-right: map_get($ihd-spacers, ($index));
}

@mixin pl($index) {
  padding-left: map_get($ihd-spacers, ($index));
}

@mixin pr($index) {
  padding-right: map_get($ihd-spacers, ($index));
}

@mixin py($index) {
  padding-top: map_get($ihd-spacers, ($index));
  padding-bottom: map_get($ihd-spacers, ($index));
}

@mixin pt($index) {
  padding-top: map_get($ihd-spacers, ($index));
}

@mixin pb($index) {
  padding-bottom: map_get($ihd-spacers, ($index));
}

@mixin m($index) {
  margin: map_get($ihd-spacers, ($index));
}

@mixin mx($index) {
  margin-left: map_get($ihd-spacers, ($index));
  margin-right: map_get($ihd-spacers, ($index));
}

@mixin ml($index) {
  margin-left: map_get($ihd-spacers, ($index));
}

@mixin mln($index) {
  margin-left: -1 * map_get($ihd-spacers, ($index));
}

@mixin mr($index) {
  margin-right: map_get($ihd-spacers, ($index));
}

@mixin mrn($index) {
  margin-right: -1 * map_get($ihd-spacers, ($index));
}

@mixin my($index) {
  margin-top: map_get($ihd-spacers, ($index));
  margin-bottom: map_get($ihd-spacers, ($index));
}

@mixin mt($index) {
  margin-top: map_get($ihd-spacers, ($index));
}

@mixin mb($index) {
  margin-bottom: map_get($ihd-spacers, ($index));
}
  